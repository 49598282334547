
(function ($) {
    $.fn.stepper = function (options) {
        var element = $(this);
        
        function initStepper(stepper) {
            var current_fs, next_fs, previous_fs; //fieldsets
            var opacity;
            var current = 1;
            var steps = stepper.find('fieldset').length;

            setProgressBar(current);

            // Setup click listeners.
            stepper.find('.next').on('click', function () {
                current_fs = $(this).parent().parent();
                next_fs = $(this).parent().parent().next();

                //Add Class Active
                stepper.find('.steps li').eq(stepper.find('fieldset').index(next_fs)).addClass('active');
                //show the next fieldset
                next_fs.show();
                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;
                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        next_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(++current);
            });
            stepper.find('.previous').on('click', function () {
                current_fs = $(this).parent().parent();
                previous_fs = $(this).parent().parent().prev();

                //Remove class active
                stepper.find('.steps li').eq(stepper.find('fieldset').index(current_fs)).removeClass('active');
                //show the previous fieldset
                previous_fs.show();
                //hide the current fieldset with style
                current_fs.animate({ opacity: 0 }, {
                    step: function (now) {
                        // for making fielset appear animation
                        opacity = 1 - now;
                        current_fs.css({
                            'display': 'none',
                            'position': 'relative'
                        });
                        previous_fs.css({ 'opacity': opacity });
                    },
                    duration: 500
                });
                setProgressBar(--current);
            });

            function setProgressBar(curStep) {
                var percent = parseFloat(100 / steps) * curStep;
                percent = percent.toFixed();
                stepper.find('.progress-bar').css('width', percent + '%')
            }
        }
        initStepper(element);
    }
})(jQuery);
