"use strict";

$.extend($.fn.pickadate.defaults, {
    selectMonths: true, // Creates a dropdown to control month
    selectYears: 15, // Creates a dropdown of 15 years to control year,
  
    onRender: function () {
      var $pickerInstance = this.$root;
  
      var year = this.get('highlight', 'yyyy');
      var day = this.get('highlight', 'dd');
      var month = this.get('highlight', 'mmm');
      var labelday = this.get('highlight', 'dddd');
  
      $pickerInstance.find('.picker__header').prepend('<div class="picker__date-display"><div class="picker__weekday-display">' + labelday + '</div><div class="picker__month-display"><div>' + month + '</div></div><div class="picker__day-display"><div>' + day + '</div></div><div    class="picker__year-display"><div>' + year + '</div></div></div>');
    }
  });