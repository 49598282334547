"use strict";

(function ($) {
  $(document).ready(function () {
    $(document).on('click', '.chip .close', function () {
      var $this = $(this);

      if ($this.closest('.chips').data('initialized')) {
        return;
      }

      $this.closest('.chip').remove();
    });
  });
})(jQuery);