"use strict";

(function ($) {
    $.fn.L3hdsResponsiveTable = function (options) {
        var tables = $(this);

        function setTableHeaders(table) {
            table.find('thead tr th').each(function() {
                var headerContent = $(this).text();
                var colIndex = $(this).index();

                table.find('tbody tr').each(function() {
                    var dataCol = $(this).children().eq(colIndex);
                    var theadElement = $('<span />', {'class': 'thead'}).html(headerContent);
                    dataCol.prepend(theadElement);
                })
            })
        }

        if (tables.length) {
            tables.each(function() {
                setTableHeaders($(this));
            });
        }
    }
    
})(jQuery);